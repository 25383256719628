<template>
  <section id="menu">
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="4"
          sm="6"
          v-for="(menu, index) in menus"
          :key="index"
        >
          <base-card height="300" color="grey lighten-1" dark>
            <v-img
              :src="menu.image"
              height="100%"
              gradient="rgba(0, 0, 0, .6), rgba(0, 0, 0, .2)"
            >
              <v-row class="fill-height text-right ma-0">
                <v-col cols="12">
                  <v-chip
                    label
                    class="mx-0 mb-2 text-uppercase"
                    color="grey darken-3"
                    text-color="white"
                    small
                    @click.stop=""
                    v-if="menu.category"
                  >
                    {{ menu.category }}
                  </v-chip>

                  <h3 class="display-1 font-weight-bold mb-2">
                    {{ menu.title }}
                  </h3>

                  <div class="caption" v-html="menu.text"></div>
                </v-col>

                <v-col align-self="end">
                  <v-chip
                    v-if="menu.next"
                    class="text-uppercase ma-0"
                    color="black"
                    label
                    small
                    :to="menu.next"
                  >
                    MORE
                  </v-chip>
                </v-col>
              </v-row>
            </v-img>
          </base-card>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "Menu",
  data() {
    return {
      menus: [
        {
          title: "Member",
          text:
            "I. Jurry A Romero<br>I. Jökari Buttgereit<br>II. Pit★Me Hooper<br>IV. Urethane De Palma<br>VI. Fam Raimi",
          image: require("@/assets/menu/menu06.jpg"),
        },
        /*
{
          title: "Gallery",
          text:
            "Latest information about ゴメスノナハゴメス.<br>There is nothing much, so you don't have to click it.",
          image: require("@/assets/menu/menu02.jpg"),
          next: "/gallery",
        },
        */
        {
          title: "Movies",
          image: require("@/assets/menu/menu02.jpg"),
          next: "/movies",
        },
        {
          title: "SONGS",
          image: require("@/assets/menu/menu04.jpg"),
          text: "You can listen to all our songs.",
          next: "/songs",
        },
        /*

        {
          title: "Live",
          image: require("@/assets/menu/menu05.jpg"),
          text: "This content includes past live information.",
          next: "/live",
        },
        {
          title: "News",
          image: require("@/assets/menu/menu06.jpg"),
          text: "Stay tuned for updates!",
        },
        */
      ],
    };
  },
};
</script>

<style></style>
